import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { StoreRef } from '../../hooks/store';

export default function SelectList({setReferentiel}:any) {

  const [refDisplay, setRefDisplay] = React.useState('');
  const {search, response, isLoading} = StoreRef();
  let filteredResponse = response?.filter((result) => result.key === 'TAXREF' || result.key === 'GBIF_BBT');

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (refDisplay) {
      setReferentiel(refDisplay);
    }
  }, [refDisplay]);

  if (isLoading && !response) {
    return null
  }

  return (
    <Box sx={{ mt: 2, mb: 2, minWidth: 120 }}>
      <FormControl fullWidth>
          <InputLabel>Sélection du référentiel de taxonomie</InputLabel>
          <Select
            value={refDisplay}
            onChange={event => {
              setRefDisplay(event.target.value as string);
              StoreRef.setState({isFinished: true});
            }}
            label = "Sélection du référentiel de taxonomie"
          >
            {response && filteredResponse?.map(result => (
                <MenuItem key={result.key} value={result.key}>
                  {result.name}
                </MenuItem>
            ))}
          </Select>
      </FormControl>
    </Box>
  );
}