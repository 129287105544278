import { useEffect } from 'react';
// Data Import
import { FileArray } from '../../utils/types';
import { TaxaNameSelection } from './SelectTaxaName';
import { StoreStatus } from '../../hooks/store';
// MUI Table Import
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef
} from '@mui/x-data-grid';

export default function MatchingTable({fileData, handleNext, tab, setStatusList}:any) {
  const { upload, response } = StoreStatus();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }
  const apiRef = useGridApiRef();

  var initialRows: GridRowsProp = [{}];
  let values:FileArray[] = fileData.consolidateReport? fileData.consolidateReport.values:null;
  initialRows = values.map((item:object, index:number) => ({ ...item, id: index + 1 }));
  const [rows, setRows] = React.useState(initialRows);
  const [validRow, setValidRow] = React.useState(false);

  function handleStatusUpload() {
    console.log(fileData);
    const importType = tab === 0 ? "biblio" : "status-biblio";
    try {
      upload(fileData, importType).then(() => {
        setStatusList(response);
        handleNext();
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(rows.length == rows.filter((row) => row.validName).length) {
      setValidRow(true);
    }
  }, [rows]);

  const columns: GridColDef[] = [
    {
      field: 'leftValueName',
      headerName: 'Nom cité',
      renderHeader: () => (
        <div style={{ 
          fontWeight: 'bold'
        }}>
          {'Nom cité'}
        </div>
      ),
      width: 400,
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'rightValueName',
      headerName: 'Nom consolidé',
      renderHeader: () => (
        <div style={{
          fontWeight: 'bold'
        }}>
          {'Nom consolidé'}
        </div>
      ),
      renderEditCell: (params) => {
        var id = params.id;
        return (
          <TaxaNameSelection rowId={id} setRows={setRows} />
        );
      },
      renderCell: (params) => {
        return (
          <div>
            {params.value}
          </div>
        );
      },
      width: 400,
      align: 'left',
      headerAlign: 'left',
      editable: true
    },
    // Colomne de similarité non utilisée car imprecise

    // {
    //   field: 'similarity',
    //   renderHeader: () => (
    //     <div style={{ 
    //       fontWeight: 'bold'
    //     }}>
    //       Similarité
    //     </div>
    //   ),
    //   width: 120,
    //   align: 'center',
    //   headerAlign: 'center'
    // }
    {
      field: 'validName',
      headerName: 'Nom valide',
      renderHeader: () => (
        <div style={{ 
          fontWeight: 'bold'
        }}>
         {'Nom valide'}
        </div>
      ),
      width: 300,
      align: 'left',
      headerAlign: 'left'
    }
  ];

  return (
    <Box
      sx={{
        height: 450,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
        processRowUpdate={(_oldRow, newRow) => {
          fileData.consolidateReport.values[newRow.id - 1] = newRow;
          return newRow;
        }}
        slots={{ toolbar: CustomToolbar }}
        apiRef={apiRef}
      />
      <Box
        sx={{
          display:"flex",
          justifyContent:" center",
          m: 2
        }}
      >
        <Button
          startIcon={<FileDownloadIcon />}
          color='primary'
          onClick={() => apiRef.current.exportDataAsCsv()}
          variant='outlined'
        >
          Exporter
        </Button>
      </Box>
      {tab == 1 && (
        <Box
          sx={{
            display:"flex",
            justifyContent:" center",
            m: 2
            }}
        >
          {validRow === true && (
            <Button
              color='primary'
              onClick={handleStatusUpload}
              variant='contained'
            >
              Générer la liste de statuts
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}