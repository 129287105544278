import * as React from 'react';
import TextField from '@mui/material/TextField';
import { StoreTerrUnit } from '../../hooks/store';
import { TerrUnitArray } from '../../utils/types';
import { useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

export default function UnitSelection({setTerrUnit}:any) {

  const [filteredResults, setFilteredResults] = React.useState<TerrUnitArray[]>([]);
  const {search, response} = StoreTerrUnit();
  const [searchInput, setSearchInput] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const loading = open && searchInput.length > 2  && filteredResults != ([]);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        setFilteredResults([]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  function searchItems (_event: React.SyntheticEvent, value: string)  {
    setSearchInput(value)
    if (value !== '' && value.length > 2) {
      search(value).then(() => {
        if (response) {
          setFilteredResults(response);
        }
      });
    }
  }

  return (
    <Autocomplete
      multiple
      id="terrunit-search"
      options={response?.filter(i => i.level === 3 || i.level === 2 || i.level === 1) ?? []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, name) => option.name === name.name}
      onInputChange={searchItems}
      onChange={(_event, value) => {
        setTerrUnit(value);
      }}
      loading={loading}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.key}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Sélection des unités territoriale"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}