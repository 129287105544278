import Typography from '@mui/material/Typography';
import { useState, useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import { StoreFile } from '../../hooks/store';
import { fillArray, TableCSV } from './Table';
import Papa from 'papaparse';

export default function ImporterCSV({ setFile }: any) {

  const [error, setError] = useState(false);
  const [table, setTable] = useState(false);
  const [fileName, setFileName] = useState<File>();

  const fileReader = new FileReader();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    var fileInit:File = acceptedFiles[0];
    setFileName(fileInit);
    setFile(fileInit);
    if (fileInit) {
      fileReader.onload = function (event) {
      const text = event.target?.result as string;
      let regex = /scientificName/g;
      let match = text.match(regex);
      match === null ? setError(true) : setError(false);
      if (text) {
        csvFileToArray(text);
        setTable(true);
      } else {
        setTable(false);
      }
    };
    fileReader.readAsText(fileInit);
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({accept: {'text/csv*': [".csv"]}, onDrop});
  
  const csvFileToArray = (string: string) : Array<{ scientificName: string }> => {
  
    const cleanedString = string;
    
    const parseResult = Papa.parse(cleanedString, {
      header: true,
      delimiter: ';',
      skipEmptyLines: true
    });
  
    if (parseResult.errors.length > 0) {
      console.error('Erreurs de parsing:', parseResult.errors);
    }

    const arrayTable = parseResult.data;
    const csvString = [
      [
        "scientificName"
      ],
      ...arrayTable.map((i :any) => [
        i.scientificName,
      ])
    ].join("\n");

    console.log(csvString);

    var parsedRow = arrayTable?.map((row: any, index: number) => ({
      id: index + 1,
      scientificName: row.scientificName
    }));

    setFile(csvString);
    fillArray(parsedRow);
    StoreFile.setState({isFinished: true});
  
    return parseResult.data
      .map((row: any, index: number) => ({
        id: index + 1,
        scientificName: row.scientificName
      }))
      .filter(row => row.scientificName);
  };

  return (
    <div className="container">
      <div {...getRootProps(
        {style :
          {display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          borderWidth: 2,
          color: '#808080',
          borderColor: '#bdbdbd',
          borderStyle: 'dashed',
          outline: 'none',}
        })}>
        <input {...getInputProps()}/>
        {!fileName && (
          <p>
            Déposez ou cliquez pour importer votre fichier CSV
          </p>
        )}
        {fileName && (
          <p>{fileName.name}</p>
        )}
      </div>

      {error && (
        <Typography sx={{ mt: 2, mb: 1, color: "red" }}>
          La colonne "scientificName" n'a pas été trouvée
        </Typography>
      )}
      {table && !error && (
        <TableCSV />
      )}
    </div>
  );
}