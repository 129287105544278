import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

export async function callMsGraph() {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    const bearer = `Bearer ${response.accessToken}`;
    /*const headers = new Headers();
    

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };*/

    return bearer;/*fetch(graphConfig.graphMeEndpoint)
        .then(response => response.json())
        .catch(error => console.log(error));*/
}