import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material/styles";

// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#219170',
    },
    secondary: {
      main: '#17664f',
    },
    error: {
      main: '#8B0000',
    },
    background: {
      default: '#fff',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
        },
      },
    },
  },
});