import { LinnaeApi } from "./configs/axiosConfigs";

export const LinnaeService = {
	postFile: async function (formdata:FormData, importType:String) {
    const response = await LinnaeApi.request({
        url: `/tool/${importType}`,
        method: "POST",
        data: formdata
      })
    return response.data
  },
  postConsolidation: async function (file:FormData) {
    const response = await LinnaeApi.request({
        url: `/tool/validTable`,
        method: "POST",
        data: file
      })
    return response.data
  }
}