import { RefApi } from "./configs/axiosConfigs";

export const RefService = {
  getAllRefType: async function () {
    const response = await RefApi.request({
        url: `/tx_ref/types?target=web`,
        method: "GET",
      })
    return response.data
  },
  getTerrUnits: async function (query:string) {
    const response = await RefApi.request({
        url: `/terr_unit/search`,
        method: "GET",
        params: {
            query: query,
          },
      })
    return response.data
  },
  getTaxaVersion: async function () {
    const response = await RefApi.request({
        url: `/tx_ref/TAXREF/version`,
        method: "GET",
      })
    return response.data
  },
  getTaxaName: async function (query:string) {
    const response = await RefApi.request({
        url: `/TAXREF/17.0/FRANCE/taxon/search/${query}`,
        method: "GET",
        params: {
            limit: 20,
            offset: 0
          },
      })
    return response.data
  },
  getTaxaSynonym: async function (key:string) {
    const response = await RefApi.request({
        url: `/TAXREF/17.0/taxon/${key}/synonyms`,
        method: "GET",
      })
    return response.data
  },
  getTaxaDetails: async function (key:string) {
    const response = await RefApi.request({
      url:`/TAXREF/17.0/FRANCE/taxon/${key}`,
      method: "GET",
    })
  return response.data
}
}