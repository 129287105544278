import Box from '@mui/material/Box';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

export default function StatusTable({statusList, terrUnit}: any) {

  var rows: GridRowsProp = [{}];
  let values: any[] = statusList;
  rows = values.map((item: object, index: number) => ({ ...item, id: index + 1 }));
  const columns: GridColDef[] = [
    terrUnit.map((unit: string) => {
      return {
        field: unit,
        headerName: unit,
        renderHeader: () => (
          <div style={{
            fontWeight: 'bold'
          }}>
            {unit}
          </div>
        )
      }
    })
  ];

  return (
    <Box
      sx={{
        height: 450,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
      />
    </Box>
  );
}