import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logolinnae from "../assets/images/linnae-logo.png";
import Aside from "../components/app/AsideLogin";
import MainStepper from "../components/app/MainStepper";

export function Home() {
  return (
      <>
        <AuthenticatedTemplate>
          <Container maxWidth="lg">
            <MainStepper />
          </Container>
        </AuthenticatedTemplate>
        
        <UnauthenticatedTemplate>
          <Aside />
          <Box
            sx = {{
              textAlign: "center",
              position: "fixed",
              top: "30%",
              left: "55%",
              zIndex: 5,
            }}
          >
          <Box
            component="img"
            sx={{
              width: "375px",
              height: "300px",
            }}
            src={logolinnae}
          />
            <Typography
              color="inherit"
              variant="h5"
              fontWeight={600}
            >
              Linnae
            </Typography>
          </Box>
        </UnauthenticatedTemplate>
      </>
  );
}
