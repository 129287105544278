import Typography from '@mui/material/Typography';
import { useState, useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import { StoreFile } from '../../hooks/store';
import { fillArray, TableCSV } from './Table';

export default function ImporterCSV({ file, setFile }: any) {

  const [error, setError] = useState(false);
  const [table, setTable] = useState(false);

  const fileReader = new FileReader();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    var fileInit:File = acceptedFiles[0];
    setFile(fileInit);
    if (fileInit) {
      fileReader.onload = function (event) {
      const text = event.target?.result as string;
      let regex = /scientificName/g;
      let match = text.match(regex);
      match === null ? setError(true) : setError(false);
      if (text) {
        csvFileToArray(text);
        setTable(true);
      } else {
        setTable(false);
      }
    };
    fileReader.readAsText(fileInit);
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({accept: {'text/csv*': [".csv"]}, onDrop});

  const csvFileToArray = (string: string) => {
    const csvHeader = string
      .slice(0, string.indexOf("\n"))
      .split(";")
      .map(header => header.replace("\r", ""));
    const csvRows = string
      .slice(string.indexOf("\n") + 1)
      .split("\n")
    var indexOfScientificName = csvHeader.indexOf("scientificName");
    const arrayTable:any = csvRows.filter(i => i !=="" && i != "\r" && i != ";\r").map(i => {
      const values = i.split(";");
      const obj = csvHeader.filter(item => item === "scientificName")
      .reduce((object:any, header) => {
        object[header] = values[indexOfScientificName];
        return object;
      }, {});
      return obj;
    });
    fillArray(arrayTable);
    StoreFile.setState({isFinished: true});
  };

  return (
    <div className="container">
      <div {...getRootProps(
        {style :
          {display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          borderWidth: 2,
          color: '#808080',
          borderColor: '#bdbdbd',
          borderStyle: 'dashed',
          outline: 'none',}
        })}>
        <input {...getInputProps()}/>
        {!file && (
          <p>
            Déposez ou cliquez pour importer votre fichier CSV
          </p>
        )}
        {file && (
          <p>{file.name}</p>
        )}
      </div>

      {error && (
        <Typography sx={{ mt: 2, mb: 1, color: "red" }}>
          La colonne "scientificName" n'a pas été trouvée
        </Typography>
      )}
      {table && !error && (
        <TableCSV />
      )}
    </div>
  );
}