import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "6ee7be3b-700b-4195-97f0-a72595045d15",
        authority: "https://login.microsoftonline.com/a0f7e121-ca5f-4ba9-b64a-82221790d245",
        redirectUri: "https://l4e.b5e.dev",
	//postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["openid", "api://afdcc88c-648b-46fa-9ad8-347300df746f/access"]
};
 
// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
